import './App.css';
import Income from './Components';

function App() {
  return (
  <>
    <Income />
  </>
  );
}

export default App;
